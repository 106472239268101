<template>
    <div class="bg-white shadow-lg rounded-lg mr-3 ml-3 pb-5 mt-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">推廣大圖資訊</div>
                <div class="inputs">
                    <div class="input">
                        <div class="profile" @click="goProfile">
                            <img class="profile-img" :src="inputs.avatar" alt="" />
                            <div class="name">
                                {{ inputs.name }}
                            </div>
                        </div>
                    </div>
                    <div class="input">
                        <div class="label">啟用狀態</div>
                        <el-select v-model="inputs.activated" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in openOption" :key="idx" :value="item.value" :label="item.label"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">使用期間</div>
                        <el-select v-model="inputs.duration" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in duringOption" :key="idx" :value="item.value" :label="item.label"> </el-option>
                        </el-select>
                    </div>

                    <div class="input">
                        <div class="label">期間範圍</div>
                        <el-date-picker
                            v-model="inputDates"
                            :disabled="!inputs.duration"
                            style="width: 100%"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
            <div class="bottom-content">
                <div class="title text-black-blod-18">推廣大圖圖片</div>
                <div class="upload-area">
                    <div class="left">
                        <div class="label">電腦版（上傳尺寸限制：{{ imageLimit.desktop.width }}*{{ imageLimit.desktop.height }}）</div>
                        <el-upload
                            action="#"
                            :limit="1"
                            :auto-upload="false"
                            :on-change="changeFiledDesktop"
                            accept=".jpg, .jpeg, .png"
                            list-type="picture"
                            :file-list="fileList1">
                            <button v-if="!imgObj1.url" class="orange-btn-outline-800 md:text-sm text-xs mr-1 w-[113px] h-[35px]">上傳圖片</button>
                            <div slot="file"></div>
                        </el-upload>
                        <div v-if="imgObj1.url" class="display-img">
                            <div class="error" @click="handleRemove(1)">
                                <i class="fas fa-times"></i>
                            </div>
                            <img :src="imgObj1.url" />
                        </div>
                    </div>
                    <div class="right"></div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="black-btn-outline-lg text-md mr-5" @click="goBack">返回列表</button>
            <button v-permission="['delete']" class="orange-btn-outline-800-lg text-md mr-5" @click="deleteData">刪除資料</button>
            <button v-permission="['update']" class="orange-btn-800-lg text-md" @click="update">儲存變更</button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// base64 轉 Blob
import { dataURLtoBlob, urlToBase64 } from "@/service/imageTransfer.js";

const defaultImg = {
    url: "",
    file: null,
    base64: "",
};
export default {
    name: "EditPromo",
    components: {
        LoadingComponent,
    },
    computed: {
        // 啟用選項
        openOption() {
            return [
                { label: "啟用", value: true },
                { label: "停用", value: false },
            ];
        },
        // 使用期間選項
        duringOption() {
            return [
                { label: "不限使用期間", value: false },
                { label: "限定使用期間", value: true },
            ];
        },
    },
    data() {
        return {
            id: "",
            loading: false,
            disabled: false,
            // 輸入匡狀態
            inputs: {
                id: 0,
                avatar: "",
                cover: "",
                name: "",
                actived: true,
                duration: true,
                start: "",
                end: "",
            },
            // 額外處理輸入
            inputDates: ["", ""],
            // 畫面呈現 image 暫存物件
            imgObj1: { ...defaultImg },
            fileList1: [],
            imageLimit: {
                desktop: {
                    width: 1024,
                    height: 768,
                },
            },
        };
    },
    methods: {
        // 刪除圖片
        handleRemove(num) {
            if (num === 1) {
                this.imgObj1 = { ...defaultImg };
                this.fileList1 = [];
            }
        },
        // 更新桌機圖片
        changeFiledDesktop(file, fileList) {
            if (file.size / (1024 * 1024) > 5) {
                this.fileList1 = [];
                alert("檔案大小超出5mb");
                return;
            }
            this.fileList1 = fileList.slice(-3);
            this.imgObj1.url = file.url;
            this.imgObj1.file = file.raw;
            const reader = new FileReader();
            // 讀取上傳檔案
            reader.readAsDataURL(file.raw);
            // 將上傳檔案轉為base64
            reader.onload = () => {
                this.imgObj1.base64 = reader.result;
                // 確認 圖片 size
                this.checkImageLimit(this.imgObj1.base64, this.imageLimit.desktop);
            };
        },
        // 確認圖片寬高
        checkImageLimit(base64, limit) {
            const img = new Image();
            img.src = base64;
            img.onload = () => {
                if (img.height !== limit.height || img.width !== limit.width) {
                    this.fileList1 = [];
                    this.imgObj1 = { ...defaultImg };
                    alert("圖片不符合寬高限制");
                }
            };
        },
        // 刪除 api
        deleteData() {
            const opts = {
                title: "刪除資料",
                message: "所選資料刪除後將無法復原",
            };
            this.$pop.popConfirm(opts).then(
                () => {
                    // 確認
                    this.$api
                        .DeletePromo(this.id)
                        .then(() => {
                            this.$message({
                                type: "success",
                                message: "刪除成功",
                            });
                            this.goBack();
                        })
                        .catch(() => {
                            this.$message({
                                type: "error",
                                message: "刪除失敗",
                            });
                        });
                },
                () => {
                    // 取消
                    return;
                }
            );
        },
        // 前往服務商頁面
        goProfile() {
            window.open(`/#/user_list/provider_update/${this.inputs.id}`);
        },
        // 返回列表
        goBack() {
            this.$router.push({ name: "promote_img" });
        },
        // 更新
        update() {
            const { end, start, activated, duration } = this.inputs;
            if (duration) {
                if (!start || !end) {
                    alert("使用期間未填寫完整");
                    return;
                }
            }
            if (!this.imgObj1.url) {
                alert("圖片未正確填入");
                return;
            }

            this.loading = true;
            const result = new FormData();

            result.append("category", 1);
            result.append("duration", duration ? 1 : 0);

            if (duration) {
                result.append("start", start);
                result.append("end", end);
            }

            if (this.imgObj1.base64) {
                const blob = dataURLtoBlob(this.imgObj1.base64);
                result.append("image", blob);
            }

            result.append("activated", activated ? 1 : 0);

            this.$api
                .UpdatePromo(this.id, result)
                .then((res) => {
                    console.log(res);
                    this.$message({
                        type: "success",
                        message: "編輯成功",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        type: "error",
                        message: "編輯失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    watch: {
        // 監聽路由
        "$route.params.id"() {
            window.location.reload();
        },
        // 監聽 element-ui 日期輸入匡，將值設定於輸入匡變數中
        inputDates(val) {
            if (val === null || val.length === 0) {
                this.inputs.start = "";
                this.inputs.end = "";
                return;
            }
            this.inputs.start = val[0];
            this.inputs.end = val[1];
        },
    },
    async mounted() {
        this.id = this.$route.params.id;
        this.loading = true;
        const { status, data } = await this.$api
            .GetPromoList()
            .then((res) => {
                const data = res.data[1].providers.find((i) => i.banana_id == this.id);
                if (!data) {
                    this.$router.push({ name: "promote_img" });
                }
                return { status: res.status, data };
            })
            .catch(() => {
                this.$message({
                    type: "error",
                    message: "取得資料失敗",
                });
                return;
            })
            .finally(() => {
                this.loading = false;
            });
        if (status == 200) {
            this.inputs = {
                id: data.id,
                avatar: data.avatar,
                cover: data.cover,
                name: data.name,
                activated: data.activated === "1",
                duration: data.duration?.start ? true : false,
                start: data.duration ? moment(data.duration?.start).format("YYYY-MM-DD") : "",
                end: data.duration ? moment(data.duration?.end).format("YYYY-MM-DD") : "",
            };
            if (data.duration) {
                this.inputDates = [
                    data.duration ? moment(data.duration?.start).format("YYYY-MM-DD") : "",
                    data.duration ? moment(data.duration?.end).format("YYYY-MM-DD") : "",
                ];
            }

            // 載入圖片到imageObj
            this.imgObj1.url = data.cover;
            urlToBase64(data.cover, (myBase64) => {
                this.imgObj1.base64 = myBase64;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            border-bottom: 1px solid #757575;
            .input {
                width: 48%;
                padding: 10px 0px;
                .profile {
                    display: flex;
                    align-items: center;
                    .profile-img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                    .name {
                        color: #ff5733;
                        text-decoration: underline;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
            img {
                width: 100%;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
